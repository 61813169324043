import Link from 'next/link'
import navStyles from '../styles/Nav.module.css'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import { BoxArrowDown, CardText, Envelope, PencilSquare, Terminal, PersonSquare, PersonVideo3} from 'react-bootstrap-icons'
import RegisterModal from './User/RegisterModal'
import { useAuth } from '../contexts/Auth'
import LogoutUserButton from './User/LogoutUserButton'
import {useState, useEffect} from "react";
import Cookies from "./Cookies"
import Image from 'next/image'
import { Router, useRouter } from 'next/dist/client/router'

//passed into Layout.js
export default function Navigation() {

    const { user, signInGoogle, signOut, signIn } = useAuth();
    const [show, setShow] = useState(false);  //for register modal

    const [scrollDirectionUp, setScrollDirectionUp] = useState(null) //for navbar class
    const [prevOffset, setPrevOffset] = useState(0) //for tracking scroll distance
    
    const [showPolicy, setShowPolicy] = useState(null) //for cookies policy notification

    const router = useRouter()

    const toggleScrollDirection = () => {
        let scrollY = window.scrollY
        if (scrollY === 0) {
            setScrollDirectionUp(null)
        }
        //if scrolling down, else if scrolling up
        if (scrollY > prevOffset) {
            setScrollDirectionUp(false)
        } else if (scrollY < prevOffset) {
            setScrollDirectionUp(true)
        }
        setPrevOffset(scrollY)
    }

    useEffect(() => {
        const cookies = localStorage.getItem('cookies');
        async function setCookies() {
            if (!cookies) {
                localStorage.setItem("cookies", 1)
                setShowPolicy(true)
                // console.log("No cookies", cookies)  
            }          
        }
        setCookies()

        async function handleNavClass() {
            window.addEventListener("scroll", toggleScrollDirection)
            return () => {
                window.removeEventListener("scroll", toggleScrollDirection)
            }
        }
        handleNavClass()
    })

    if (router.asPath.includes('meeting')){
        return null
    }

    return (
        <Navbar 
            collapseOnSelect 
            expand="lg" 
            className={scrollDirectionUp ? `sticky-top ${navStyles.nav}` : navStyles.nav}
            variant="light">
            <Container>
                <Navbar.Brand href="/">
                    <Image 
                        alt="Ordinary Coders" 
                        src="https://d2gdtie5ivbdow.cloudfront.net/static/img/ordinarycoders_logo.png" 
                        width={180} height={20} 
                        objectFit="contain"
                        className="d-inline-block" 
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto">
                        {[
                        // { page: "Plaza", url: "https://plaza.so/?aff=nor54", icon: <PersonVideo3 className={navStyles.icon} /> },
                        // { page: "Course", url: "/build-a-django-web-app",  icon:<Terminal className={navStyles.icon} />},
                        { page: "Publish", url: "/publish",  icon:<PencilSquare className={navStyles.icon} />},
                        { page: "Blog", url: "/blog/articles",  icon:<CardText className={navStyles.icon} />},
                        { page: "Contact", url: "/contact",  icon:<Envelope className={navStyles.icon} />},
                        ].map(({ page, url, icon }) => (
                            <Link href={url} key={page} passHref>
                                 <Nav.Link rel="dofollow">
                                     {icon} {page}
                                 </Nav.Link>
                            </Link>
                        ))}
                            {user ? <LogoutUserButton signOut={signOut} user={user} /> : (
                                <>
                                      <Button variant="purple" size="sm" onClick={() => setShow(true)}>
                                        Sign up or Login
                                    </Button>
                                    <RegisterModal 
                                        show={show}
                                        signInGoogle={signInGoogle} 
                                        signIn={signIn} 
                                        setShow={setShow} />
                                </>
                            ) 
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
            {/* Cookie policy */}
            <Cookies showPolicy={showPolicy} setShowPolicy={setShowPolicy}/>
        </Navbar>
    )
}
