import Link from 'next/link'
import { BoxArrowRight } from 'react-bootstrap-icons'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import navStyles from '../../styles/Nav.module.css'
import { supabase } from '../../utils/supabaseClient';
import { useEffect, useState } from 'react'


export default function LogoutUserButton(props) {
    const [profile, setProfile] = useState(null);


    useEffect(() => {
        async function fetchMyAPI() {
            const { data, error } = await supabase
                .from("main_profile")
                .select(`
                    user,
                    username
                `)
                .filter('user', 'eq', props.user.id)
            error && console.log(error)

            if (data.length > 0) {
                setProfile(data[0].username)
                return true
            } else {
                return false
            }
            
        }
        const val = fetchMyAPI()
        if (!val) {
            const timer = setTimeout(() => {
                fetchMyAPI()
              }, 4000);
            return () => clearTimeout(timer);
        }
    }, [])

    
    return (
        <>
            {/* <Link href="/" passHref> */}
                <Nav.Link onClick={ props.signOut }>
                    <BoxArrowRight className={navStyles.icon}/>
                        Logout
                </Nav.Link>
            {/* </Link> */}
            
            <Link href="/userpage" passHref>
                {!profile ? 
                    <Button variant="white" size="sm">
                        {/* <Spinner animation="border" role="status" size={"sm"}> */}
                            <span >Loading...</span>
                        {/* </Spinner> */}
                    </Button>
                :
                    <Button variant="white" size="sm">
                            {profile}
                    </Button>

               
                }
            </Link>
        </>
    )
}