import Image from "next/image"
import { cloudfront } from "../../config";
import Button from "react-bootstrap/Button";
import navStyles from '../../styles/Nav.module.css'
import {signIn} from "next-auth/client";
import {server} from '../../config'


export default function GoogleOAuth(props) {
    return (
      <div className="text-center"  style={{ display: 'flex', alignItems:' center', justifyContent: 'center' }} onClick={() => {
        const url = props.next ? server + '/callback/google?next='+ props.next : server + '/callback/google'
        signIn("google", { callbackUrl: url })
      }}>
        <Button className={navStyles.google_button} variant="white">
          <Image src={`${cloudfront}/static/img/g-logo.png`}  
            width={18} 
            height={18} 
            alt="Google" />
          <span className={navStyles.google_sign}>Sign in with Google</span>
        </Button>
      </div>
     
    )
}