import { supabase } from "../utils/supabaseClient"

export const dev = process.env.NODE_ENV !== 'production'

export const server = dev ? 'http://localhost:3000' : 'https://ordinarycoders.com'

export const cloudfront = 'https://d2gdtie5ivbdow.cloudfront.net'

export const commentFetcher = async arr => {
    const [column, id] = arr
    const { data, error } = await supabase
      .from("main_comment")
      .select(`
        id,
        post,
        data,
        profile (
          username,
          user,
          is_superuser
        )
      `)
      .filter(column, "eq", id)
      return data
}

export const STRIPE_COUNTRIES = [
  'AR', 'AU', 'AT', 'BE', 'BO', 'BG', 'CA',
  'CL', 'CO', 'CR', 'HR', 'CY', 'CZ', 'DK',
  'DO', 'EG', 'EE', 'FI', 'FR', 'DE', 'GR',
  'HK', 'HU', 'IS', 'IN', 'IE', 'IL', 'IT',
  'LV', 'LI', 'LT', 'LU', 'MT', 'MX', 'NL',
  'NZ', 'NO', 'PY', 'PE', 'PL', 'PT', 'RO',
  'SG', 'SK', 'SI', 'ES', 'SE', 'CH', 'TH',
  'TT', 'GB', 'US', 'UY'
]