import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Link from 'next/link'
import navStyles from '../../styles/Nav.module.css'
import AuthSignIn from "./AuthSignIn";
import GoogleOAuth from "./GoogleOAuth";

//TODO:

//imported in components/Navbar.js, components/Publish/PromptItem.js, pages/publish.js
export default function RegisterModal(props) {

    return (
      <>
  
        <Modal show={props.show} onHide={() => props.setShow(false)} centered>
          <Modal.Body>
            <Tabs fill defaultActiveKey="signup" className="mb-3">

              {/* signup tab */}
              <Tab eventKey="signup" title="Sign Up">
                  <h2 className={navStyles.header}>Create an account</h2>
                  <br />
                    <GoogleOAuth signInGoogle={props.signInGoogle} next={props.next}/>
                  <br />
                  <div className={navStyles.subtext}>
                    <p>
                      By creating an OrdinaryCoders account, you agree to the following <Link href="/terms-conditions" passHref><a  className='link-purple'>Terms & Conditions</a></Link>.
                    </p>
                  </div>
              </Tab>

              {/* login tab */}
              <Tab eventKey="profile" title="Login">
                <h2 className={navStyles.header}>Login</h2>
                <br />
                <GoogleOAuth signInGoogle={props.signInGoogle} next={props.next}/>
                <br /> <br />
                    <div className="line">
                        <span className="line-text">
                          OR
                        </span>
                    </div>
                <br /> <br />
                <AuthSignIn signIn={props.signIn} handleClose={() => props.setShow(false)}/>
              </Tab>

            </Tabs>
          </Modal.Body>
        </Modal>
      </>
    )
  }