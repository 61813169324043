import React from 'react';
import { X } from 'react-bootstrap-icons';
import Link from 'next/link'

export default function Cookies(props) {

    if (props.showPolicy) {
        return (
            <div 
                id="cookies" 
                className="fixed-bottom p-3 mb-0 bg-dark" 
                style={{display:'flex', borderRadius:'0px',  display: 'flex', justifyContent: 'center'}}
            >
                <p className='text-light text-center mb-0'>
                   By using this site, you agree to our 
                   <u>
                        <Link href="/cookie-policy" prefetch={false}>
                            <a className="text-white"> Cookie Policy </a>
                        </Link>
                    </u>
                     and 
                     <u>
                         <Link href="/privacy-policy" prefetch={false}><a className="text-white"> Privacy Policy</a></Link>
                    </u>
                 </p>
                 <X onClick={() => props.setShowPolicy(false)} className="mx-3" style={{color:'white', fontSize:25}}/>
            </div>
        );
    }
    return null
}
