import React from 'react'
import { X, ExclamationCircle, InfoCircle, CheckCircle } from 'react-bootstrap-icons'
import NotificationStyles from "../styles/Notification.module.css"

const AlertTemplate = ({ message, options, style, close }) => {

  return (
    <div className={NotificationStyles.alert} 
    style={ 
        options.type === 'info' ? { ...style, backgroundImage: 'linear-gradient(to top right, #9653FE, #4729FD)'} 
        : options.type === 'success' ? { ...style, backgroundImage: 'linear-gradient(to top right, #099773, #38A169)'} 
        : options.type === 'error' ? { ...style, backgroundImage: 'linear-gradient(to top right, #E53E3E, #f40752)'}
        : {...style}}
    >
      {options.type === 'info' && <InfoCircle className={NotificationStyles.icon}/>}
      {options.type === 'success' && <CheckCircle className={NotificationStyles.icon}/>}
      {options.type === 'error' && <ExclamationCircle className={NotificationStyles.icon}/>}
      <span style={{ flex: 2}}>{message}</span>
      <button onClick={close} className={NotificationStyles.button}>
        <X />
      </button>
    </div>
  )
}

export default AlertTemplate
