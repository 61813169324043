import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import { useState } from "react"
import { supabase } from "../../utils/supabaseClient"
import pbkdf2 from "pbkdf2"


// imported to components/User/LoginModal.js
export default function AuthSignIn(props) {
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [passwordInvalid, setPasswordInvalid] = useState(false)
    const [emailInvalid, setEmailInvalid] = useState(false)

    const loginUser = async () => { 
        setLoading(true)

        if (process.env.NODE_ENV === "production"  && email !== "jamesaschwindt@gmail.com") {
            const auth_user = await supabase
            .from("auth_user")
            .select("email, password")
            .eq("email", email)
            auth_user.error && console.log(auth_user.error)
            
            if (!auth_user.data[0]) {
                setEmailInvalid(true)
                setLoading(false)
                return
            } else {
                setEmailInvalid(false)
            }
            let parts = auth_user.data[0].password.split('$')
            const iterations = parseInt(parts[1])
            const salt = parts[2]
            const pass = pbkdf2.pbkdf2Sync(password, salt, iterations, 32, 'sha256').toString('base64')
            
            const res  = await props.signIn({
                email: email,
                password: pass,
            })
            if (res.error) {
                setPasswordInvalid(true)
                setLoading(false)
                return
            } else {
                setPasswordInvalid(false)
            }
        } else {
            const res  = await props.signIn({
                email: email,
                password: password,
            })
            if (res.error) {
                setPasswordInvalid(true)
                setLoading(false)
                return
            } else {
                setPasswordInvalid(false)
            }
        }
        
      

        props.handleClose()
    }

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicUsername">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    isInvalid={emailInvalid}
                    required 
                    type="text" 
                    value={email} 
                    onChange={e => setEmail(e.target.value)} 
                />
                <Form.Control.Feedback type="invalid">
                    Email not found. Try again or sign-in with Google
                </Form.Control.Feedback>
            </Form.Group>
               
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    isInvalid={passwordInvalid}
                    required 
                    type="password" 
                    value={password} 
                    onChange={e => setPassword(e.target.value)} 
                />
                <Form.Control.Feedback type="invalid">
                     Incorrect Password
                </Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid gap-2">
                <Button 
                    variant="purple" 
                    onClick={loginUser}
                    disabled={loading}
                >
                    {loading ? <Spinner  size="sm" animation="border" variant="light" /> : <span>Login</span>}
                </Button>
            </div>
           
        </Form>
    )
}