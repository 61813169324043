import Layout from '../components/Layout'
import '../styles/scss/global.scss'
import '../styles/globals.css'
import { AuthProvider } from '../contexts/Auth'
import "prismjs/themes/prism-okaidia.css";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from '../components/Notification'
import { useRouter } from 'next/router'
import { useEffect } from "react";
import Script from 'next/script'
import * as gtag from '../lib/gtag'

// optional configuration for react-alerts
const alertOptions = {
  // you can also just use 'bottom right'
  position: positions.BOTTOM_RIGHT,
  timeout: 3000,
  offset: '10px',
  containerStyle: {'zIndex':'9999'},
  // you can also just use 'fade'
  transition: transitions.FADE,
}

export default function MyApp({ Component, pageProps }) {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
        <Script
    async 
    strategy="afterInteractive"
    src="//www.ezojs.com/ezoic/sa.min.js"
  />
    <Script
    async 
    strategy="afterInteractive"
    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6897120283118523"
  />
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtag.GA_TRACKING_ID}');
        `}
      </Script>
    <AlertProvider template={AlertTemplate} {...alertOptions}>
        <AuthProvider>  
            <Layout>
              <Component {...pageProps} />
            </Layout>
        </AuthProvider>
    </AlertProvider>
    </>
  )
}
